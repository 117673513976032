import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import {
  Box,
  Flex,
  Image,
  Button,
  extendTheme,
  Select,
} from "@chakra-ui/react";
import CardProductos from "./ProductosCard";
import { db } from "../firebaseconfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { v4 } from "uuid";
function Productos() {
  const docs = [];
  const [Productos, setProductos] = useState([]);
  const verProductos = async () => {
    const q = query(collection(db, "Productos"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docs.push(doc.data());
      console.log(docs);
    });
    setProductos(docs);
    console.log(Productos);
  };

  useEffect(() => {
    verProductos();
  }, []);

  return (
    <Box>
      <Menu />
      <Box h={[30, 40, 50, 99]} bg="#78c5ee"></Box>
      <Box bg="linear-gradient(0deg, rgba(255, 255, 255, 1) 5%, rgba(120, 197, 238, 1) 95%)">
        <Flex alignItems="center" justifyContent="space-evenly" wrap="wrap">
          {Productos.map((productos) => {
            return (
              <Box key={v4()}>
                <CardProductos
                  imagen={productos.Imagen}
                  imagen2={productos.Imagen2}
                  S={productos.S}
                  M={productos.M}
                  L={productos.L}
                  XL={productos.XL}
                  id={v4()}
                  modelo={productos.Modelo}
                  car={productos.Carrucel}
                />
              </Box>
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
}

export default Productos;
