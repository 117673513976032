import './App.css';
import {  Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Productos from './components/Productos';



function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/Productos' element={<Productos/>}/>
      </Routes>
    </div>
  );
}

export default App;
