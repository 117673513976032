import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Image,
  Stack,
  Heading,
  Divider,
  Text,
  Button,
  ButtonGroup,
  Select,
  Flex,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

function CardProductos(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const handleTalla = () => {
    const talla = document.getElementById(props.id).value;
    switch (talla) {
      case "S":
        window.open(props.S);
        break;
      case "M":
        window.open(props.M);
        break;
      case "L":
        window.open(props.L);
        break;
      case "XL":
        window.open(props.XL);
        break;

      default:
        break;
    }
  };
  return (
    <Card maxW="md" border="none" bg="none" boxShadow="none" textAlign="center">
      <CardBody>
      <div id={props.car} class="carousel slide carousel-fade" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={props.imagen} class="d-block w-100" alt="..."/>
          </div>
          <div class="carousel-item">
            <img src={props.imagen2} class="d-block w-100" alt="... "/>
          </div>
          
        </div>
          <a class="carousel-control-prev" href={"#"+props.car} role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href={"#"+props.car} role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
      </div>
        <Box textAlign='left' >
          <p>{props.modelo}</p>
          <Flex >
            <Text mr='10px'>$780</Text>
            <Text textDecoration='line-through' >$920</Text>
          </Flex>
          
        </Box>
        <Stack mt="6" spacing="3">
          <Select id={props.id} border="none" placeholder="Selecciona una Talla">
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
          </Select>
        </Stack>
      </CardBody>
      <CardFooter>
        <Flex alignItems="center" justifyContent="center">
          <Button onClick={handleTalla} bg="blue.300" color="white">
            Comprar
          </Button>
         
        </Flex>
      </CardFooter>
      
    </Card>
  );
}

export default CardProductos;
