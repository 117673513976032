import React from "react";
import { Box, Text, Flex,Button,Image, Link } from "@chakra-ui/react";
import Menu from "./Menu";
import Playera1 from "../img/fondo1.png";
import Playera2 from "../img/fondo2.png";
import Playera3 from "../img/fondo3.png";
import Playera4 from "../img/fondo4.png";
import Modelo3 from "../img/modelo3.jpg";
import Modelo2 from "../img/modelo2.jpg";
import Modelo1 from "../img/modelo1.png";
import Modelo4 from "../img/modelo4.jpg";
import Modelo6 from "../img/modelo6.jpg";
import Modelo7 from "../img/modelo7.jpg";
import Modelo5 from "../img/modelo5.jpg";
import {useNavigate} from "react-router-dom";






function Home() {
  const navigate = useNavigate();
  const handleRedirigir=()=>{
    navigate("/Productos");
  }
  return (
    <Box >
      <Menu />
      <Box h={[30,40,50,99]} bg='#78c5ee'></Box>
      <Box >
        <Flex alignItems='center' justifyContent='center' bg='linear-gradient(0deg, rgba(255, 255, 255, 1) 5%, rgba(120, 197, 238, 1) 95%)'>
          <Text marginTop='40px' fontSize={[40,50,85,85]} w='90%'>En busqueda de reconectar con nuestros sentidos.</Text>
          <br /><br />
        </Flex>
      </Box>
      <Box textAlign='left'>
        <Text marginLeft='20px' fontSize='4xl'>Mas Vendido</Text>
        <Button onClick={handleRedirigir} marginLeft='20px' border='black solid 2px' fontSize='xl' fontWeight='light' bg='none'_hover='none'  >Ver todo</Button>
        <br /><br />
        <Flex alignItems='center' justifyContent='space-evenly' wrap='wrap'>
            <Image onClick={handleRedirigir} w='300px' mt='35px' h='auto' src={Playera1}/>
            <Image onClick={handleRedirigir} w='300px' mt='35px' h='auto' src={Playera2}/>
            <Image onClick={handleRedirigir} w='300px' mt='35px' h='auto' src={Playera3}/>
            <Image onClick={handleRedirigir} w='300px' mt='35px' h='auto' src={Playera4}/>


        </Flex>
      </Box>
      <br /><br />
      <Box bg='linear-gradient(180deg, rgba(255, 255, 255, 1) 5%, rgba(120, 197, 238, 1) 95%)'>
      <Flex w='100%'  alignItems='center' justifyContent='space-evenly'>
            <Box w='50%' ml='30px'>
                <Image onClick={handleRedirigir} w='90%'  h='auto' src={Modelo3}/>
            </Box>
            <Box  w='50%'>
                <Image onClick={handleRedirigir}  w='90%' h='auto' src={Modelo2}/>
                
            </Box>
        </Flex>
        <br /><br />
        <Flex w='100%'  alignItems='center' justifyContent='space-evenly'>
            <Box w='50%' ml='30px'>
                <Image onClick={handleRedirigir} w='90%'  h='auto' src={Modelo4}/>
            </Box>
            <Box  w='50%'>
                <Image onClick={handleRedirigir}  w='90%' h='auto' src={Modelo6}/>
                
            </Box>
        </Flex>
        <br /><br />
        <Flex w='100%'  alignItems='center' justifyContent='space-evenly'>
            <Box w='50%' ml='30px'>
                <Image onClick={handleRedirigir} w='90%'  h='auto' src={Modelo7}/>
            </Box>
            <Box  w='50%'>
                <Image onClick={handleRedirigir}  w='90%' h='auto' src={Modelo5}/>
                
            </Box>
        </Flex>
        <br /><br />
      </Box>
     
    </Box>
   
  );
}
export default Home;
