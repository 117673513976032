import React from "react";
import { Box, Flex, Image, Button,extendTheme  } from "@chakra-ui/react";
import Logotipo from "../img/logotipo.png";
import {useNavigate} from "react-router-dom";

function Menu() {
  const navigate=useNavigate();
    const handleclick=(e)=>{
        navigate(`/${e.target.value}`);
    }
  return (    
   
    <Flex alignItems="center" justifyContent="center" bg='#78c5ee'  position='fixed' zIndex='1000'>
      <Image w="30%" h="auto" src={Logotipo} />
      <Box w="70%">
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button onClick={handleclick} value=''  marginRight={[2,0,0,0]} bg='none' fontSize='25px' fontWeight='light' _hover='none' >Inicio</Button>
          <Button onClick={handleclick} value='Productos'  marginRight={[2,0,0,100]} bg='none' fontSize='25px'  fontWeight='light' _hover='none'  >Comprar</Button>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Menu;
