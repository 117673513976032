// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBNdLBZSRtp5A5iuM2CR0YopvUtVOFbLto",
  authDomain: "amora-9065d.firebaseapp.com",
  projectId: "amora-9065d",
  storageBucket: "amora-9065d.appspot.com",
  messagingSenderId: "1017719316593",
  appId: "1:1017719316593:web:63f933f2d77c5c4e373bd0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
